<template>
    <div class="w-full md:w-1/2 xl:w-1/3 p-6">
                
                <div class="bg-gradient-to-b from-indigo-200 to-indigo-100 border-b-4 border-indigo-500 rounded-lg shadow-xl p-5 overflow-hidden">
                    <div class="flex flex-row items-center">
                        <div class="flex-shrink pr-4 relative">
                            <div class="rounded-full p-5 bg-indigo-600"><i class="fas fa-tasks fa-2x fa-inverse"></i></div>
                            <div
                                    class="rounded-full p-1 cursor-pointer bg-indigo-600 flex items-center absolute addBloc">
                                    <i class="fas fa-plus-circle fa-2x fa-inverse"></i></div>
                        </div>
                        <div class="flex-1 text-right md:text-center">
                            <h5 class="font-bold uppercase text-gray-600"> {{ title }} </h5>
                            <h3 class="font-bold text-3xl" >{{ nombre }} <span class="font-normal">Fbu</span>
                                <span class="text-indigo-500"><i class="fas fa-caret-up"></i></span>
                            </h3>
                        </div>
                    </div> 
                </div>
            </div>
    </template>
    <script> 
    export default {
        name : 'creditCard',
        props : {
            title : {
                default : ''
            },
            nombre : {
                default : 0
            }
        }
    }
    </script>