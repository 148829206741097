import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    }, 
    {
        path: '/articles',
        name: 'articles',
        component: ()=> import('../views/articles.vue')
    },
    {
        path: '/control',
        name: 'control',
        component: ()=> import('../views/control.vue')
    },
    {
        path:'/control/details/:id',
        name : 'details',
        component: ()=> import('../views/details.vue')
    },
    {
        path:'/credit',
        name : 'credit',
        component: ()=> import('../views/credit.vue')
    },
    {
        path:'/depences',
        name : 'depences',
        component: ()=> import('../views/depences.vue')
    },
    {
        path:'/routeTempon',
        name : 'routeTempon',
        component: ()=> import('../views/routeTempon.vue')
    },
    {
        path: '/:introuvable(.*)*',
        component: HomeView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router