<template>
    <div class="main-content flex-1 bg-gray-100 mt-1 md:mt-2 pb-24 md:pb-5 relative"> 
        <div class="flex flex-wrap" id="printableHome">
            <caisseCard title="Encaissée" :nombre="this.store.AppData.Caisse ? this.store.AppData.Caisse : 0" />
            
            <depotCard title="interet" :nombre="this.store.AppData.interet ? this.store.AppData.interet : 0" />
            
            <clientCard title="dette" :nombre="this.store.AppData.dette ? this.store.AppData.dette.total : 0" />

            <creditCard title="depences" :nombre="this.store.AppData.depences ? this.store.AppData.depences.total : 0" />

            <retraitCard title="Perte" :nombre="this.store.AppData.perte ? this.store.AppData.perte : 0" /> 

            <experienceCard v-if="this.store.AppData.startDate" :departDate="this.store.AppData.startDate ? this.store.AppData.startDate : 0" />

        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import caisseCard from '@/components/caisseCard.vue';
import clientCard from '@/components/clientCard.vue';
import creditCard from '@/components/creditCard.vue';
import depotCard from '@/components/depotCard.vue';
import retraitCard from '@/components/retraitCard.vue';
import experienceCard from '@/components/experienceCard.vue';
import { store } from '@/components/store';
export default {
    name: 'HomeView',
    data() {
        return {
            store
        }
    },
    components: { 
        caisseCard,
        clientCard,
        creditCard,
        depotCard,
        retraitCard,
        experienceCard,
    },
    async mounted() {
        let self = this;
        if (this.store.AppData.length < 2) {
            this.store.setfetching(true)
            fetch(this.store.startFetchUrl+'/getData', {
                method: "POST"
            }).then(res => res.json())
            .then((r => {
                self.store.setAppData(r) 
                self.store.setfetching(false)
                self.store.setKeepTotal(r.Caisse)
                let ArticleControl = []; 
                //si ce n'est pas le premier control 
                if (self.store.AppData.control && self.store.AppData.control.length > 0) {
                    let lastControl = self.store.AppData.control[self.store.AppData.control.length - 1]; 
                        for (const keyControl in lastControl) { // amata in control 
                            if (isNaN(parseInt(keyControl))) //ce n'est pas un nombre
                            { 
                                for (let i = 0; i < self.store.AppData.articles.length; i++) {
                                    if (self.store.AppData.articles[i].tname === keyControl) {
                                        let articleLastControl = JSON.parse(lastControl[keyControl]); 
                                        ArticleControl.push({
                                            id : self.store.AppData.articles[i].id,
                                            nom : self.store.AppData.articles[i].tname,
                                            last : parseInt(articleLastControl['reste']),
                                            reste : parseInt(articleLastControl['reste']),
                                            ravit : 0,
                                            vendu : 0,
                                            montat : 0,
                                            categ : self.store.AppData.articles[i].categori,
                                            pv : self.store.AppData.articles[i].pv,
                                            quantity : self.store.AppData.articles[i].quantity
                                        })
                                        
                                    }
                                }
                            } 
                        }     
                //si c'est le premier control
                } else if(self.store.AppData.articles && self.store.AppData.articles.length > 0) {  
                    self.store.AppData.articles.forEach(article =>{
                        ArticleControl.push({
                            id : article['id'],
                            nom : article['tname'],
                            last : article['quantity'],
                            reste : article['quantity'],
                            ravit : 0,
                            vendu : 0,
                            montat : 0,
                            categ : article['categori'],
                            pv : article['pv'],
                            quantity : article['quantity']
                        }) 
                    }) 
                }
                self.store.setArticleControl(ArticleControl)
            })).catch(e=>{
                self.store.changeFetchError('Error :)',e)
            });
        }
    }
}

</script>
