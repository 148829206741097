<template>
     <!--Nav-->
     <nav class="bg-black pt-2 md:pt-2 pb-2 px-1 mt-0 h-auto fixed w-full z-20 top-0"> 
        <div class="flex items-center justify-between">
            <div class="flex flex-shrink md:w-1/3 justify-center md:justify-start text-white">
                <router-link to="/">
                    <div class="flex justify-content items-center ml-3">
                        <img style="width:40px" src="/img/logoAlpha.png" alt="">
                    </div>
                </router-link>
            </div> 
            <h1 class="text-white text-xl">ALPHA 1.0</h1>
            <div class="flex flex-shrink md:w-1/3 justify-center md:justify-znd text-white" @click="refresh"> 
               <i class="fa fa-sync-alt mr-5" style="font-size: 20px;"></i>
            </div> 
        </div> 
    </nav>
</template>
<script> 
import { store } from '@/components/store';
export default {
    name : 'navComponent',
    data(){
        return {store}
    },
    methods : {
        refresh(){ 
            this.store.update(); 
            this.$router.push('/routeTempon');   
        }
    }
}
</script>