<template>
    <div class="flex flex-col md:flex-row">
        <verticalMenu />
        <router-view></router-view>
    </div>
</template>
<script>
import verticalMenu from "./verticalMenu.vue";
export default {
    name : 'RouterVieuw',
    components : {
        verticalMenu
    },
    data(){
        return {
            
        }
    }
}
</script>