<template>
    <div class="w-full md:w-1/2 xl:w-1/3 p-6">      
        <div class="bg-gradient-to-b from-red-200 overflow-hidden to-red-100 border-b-4 border-red-500 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4 relative">
                    <div class="rounded-full p-5 bg-red-600"><i class="fas fa-inbox fa-2x fa-inverse"></i></div>
                    <div
                        class="rounded-full p-1 cursor-pointer bg-red-600 flex items-center absolute addBloc">
                                    <i class="fas fa-plus-circle fa-2x fa-inverse"></i></div>
                </div>
                <div class="flex-1 text-right md:text-center">
                    <h5 class="font-bold uppercase text-gray-600">{{ title }}</h5>
                    <h3 class="font-bold text-3xl"> {{ nombre }} <span class="font-normal"> Fbu</span><span class="text-red-500"><i class="fas fa-caret-up"></i></span></h3>
                </div>
            </div> 
        </div>
     </div>
</template>
<script> 
export default{
    name : 'retraitCard',
    props : {
        title : {
            default : ''
        },
        nombre : {
            default : 0
        }
    }
}
</script>