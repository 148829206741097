<template>
    <div class="w-full md:w-1/2 xl:w-1/3 p-6">
        <!--En caisse Card-->
        <div
            class="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5 relative overflow-hidden ">
            <div class="flex flex-row items-center">
                <div class="flex-shrink relative pr-4">
                    <div class="rounded-full p-5 bg-green-600"><i class="fa fa-wallet fa-2x fa-inverse"></i>
                    </div>
                    <div 
                        class="rounded-full p-1 cursor-pointer bg-green-600 flex items-center absolute addBloc">
                        <i class="fas fa-plus-circle fa-2x fa-inverse"></i></div>
                </div>
                <div class="flex-1 text-right md:text-center">
                    <h5 class="font-bold uppercase text-gray-600"> <router-link to="/articles">{{ title }}</router-link> </h5> 
                    <h3 class="font-bold text-3xl">{{ nombre }} <span class="font-normal"> Fbu</span>
                        <span class="text-green-500"><i class="fas fa-caret-right"></i>
                        </span>
                    </h3>
                </div>
            </div> 
        </div>
    </div>
</template>
<script>  
export default {
    name : 'caisseCard',
    props : {
        title :{
            default : ''
        } ,
        nombre :{
            default : ''
        }
    }
}
</script>
