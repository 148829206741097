<template>
  <navComponent/>
  <RouterVieuw />
</template>

<style>
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    font-weight: bold;
    text-align: start;
}
td, th { 
    text-wrap: nowrap;
    color : black;
}
tbody tr{
   background-color: #586ea5; 
}
tbody tr:nth-of-type(odd){
    background-color: #90e290;
}
tbody tr.bgTransp{
  background-color: transparent;
}
tbody td.inheritColor,tbody th.inheritColor{
  color : inherit;
}
.table th.modalPrintablrTop {
    border-top: none;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}
tr th.indice {
    text-align: center;
} 
</style>
<script>
import navComponent from '@/components/nav.vue';
import RouterVieuw from '@/components/RouterVieuw.vue';
import { store } from '@/components/store';
export default {
  name: 'App',
  data(){
    return {
      store
    }
  },
  components : {
    navComponent,
    RouterVieuw
  }
}
</script>