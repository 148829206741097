// store.js
import { reactive } from 'vue'

export const store = reactive({  
    startFetchUrl : '',  
    fetching : false,
    fetcState : true ,
    fetchingMessage : 'Chargement...',
    setfetching(f){
        this.fetching = f
    },
    fetchError : false,
    displayError : false,
    changeDisplayError (set){
        this.displayError = set
    },
    changeFetchError(message,error){
        this.fetchingMessage = message,
        this.fetcState = false
        this.fetchError = error
        setTimeout(() => {
            this.fetching = false
            this.fetcState = true
            this.fetchingMessage = 'Chargement...' 
            if (this.displayError === false) {
                this.fetchError = false  
            }
        }, 5000);
    },
    keepTotal : 0,
    setKeepTotal(montat){
        this.keepTotal = montat 
    },
    depences : [ 
    ],
    creditResolue : [],
    CreditPayer(id){
        this.creditResolue.push(id)
    },
    updateDepences(action,data){
        if (action === 'add') {
            this.depences.push(data)
        }else if(action === 'restaurer' ){
            this.depences = [];
        }else if(action === 'delete'){
            this.depences.splice(data,1)
        }
    }
    /* fetch data */
    ,
    AppData : [] ,
    setAppData(c){
        this.AppData = c
    }

    /* after fetch data */
    ,
    ArticleControl : [] ,
    setArticleControl(control){
        this.ArticleControl = control
    },
    updateArticleControl(opt){
        this.ArticleControl.forEach(article => {
            if (article.id === opt.id) { 
                for (const key in opt) {
                    article[key] = opt[key]
                }
            }
            
        });
    },
    update(){
        this.setArticleControl([])
        this.setAppData([])
        this.depences = []
        this.creditResolue = []
    }
})