<template>
    <div class="bg-black shadow-xl h-16 fixed bottom-0 mt-12 md:relative md:h-screen z-10 w-full md:w-48">
        <div class="fetchingBar" :class="this.store.fetcState === false ? 'errorFetch' : '' " v-if="this.store.fetching" @click="this.store.changeDisplayError(true)">{{ this.store.fetchingMessage }}</div>
        <div class="displayFetchError" @click="this.store.changeDisplayError(false)" v-if="this.store.displayError && this.store.fetchError">
            {{ this.store.fetchError }}
        </div>
        <div class="md:mt-12 md:w-48 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between">
            <ul class="list-reset flex flex-row md:flex-col py-0 md:py-3 px-1 md:px-2 text-center md:text-left">
                <li class="mr-3 flex-1">
                    <router-link to="/" class="toCaisse block py-1 md:py-3 pl-1 align-middle text-white no-underline border-black border-b-2  hover:border-green-800">
                        <i class="fas fa-tachometer-alt pr-0 md:pr-3 text-white"></i><span class="pb-1 md:pb-0 text-xs md:text-base  block md:inline-block ">DashBoard</span>
                    </router-link>
                </li>
                <li class="mr-3 flex-1">
                    <router-link to="/articles" class="toClient block py-1 md:py-3 pl-1 align-middle  text-white no-underline 
                    border-black border-b-2 hover:border-pink-800">
                        <i class="fas fa-glass-cheers pr-0 md:pr-3 text-white"></i><span class="pb-1 md:pb-0 text-xs md:text-base  block md:inline-block">Articles</span>
                    </router-link>
                </li>
                <li class="mr-3 flex-1">
                    <router-link  to="/control" class=" toCredit block py-1 md:py-3 pl-1 align-middle  text-white no-underline  border-b-2 border-black hover:border-yellow-800">
                        <i class="fas fa-list pr-0 md:pr-3 text-white"></i><span class="pb-1 md:pb-0 text-xs md:text-base block md:inline-block md:text-base ">Control</span>
                    </router-link >
                </li>
                <li class="mr-3 flex-1">
                    <router-link to="/depences" class="toSetting block py-1 md:py-3 pl-0 md:pl-1 text-white align-middle no-underline border-b-2 border-black hover:border-red-500">
                        <i class="fas fa-hand-holding-usd pr-0 md:pr-3 text-white"></i><span class="pb-1  md:pb-0 text-xs md:text-base   block md:inline-block md:text-base" >Depences</span>
                    </router-link>
                </li>
                <li class="mr-3 flex-1">
                    <router-link to="/credit" class="toSetting block py-1 md:py-3 pl-0 md:pl-1 text-white align-middle no-underline border-b-2 border-black hover:border-red-500">
                        <i class="fas fa-credit-card pr-0 md:pr-3 text-white"></i><span class="pb-1  md:pb-0 text-xs md:text-base   block md:inline-block md:text-base" >Dettes</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<style>
.router-link-active.toCaisse{
    border-color:#059669;
    border-bottom-width: 2px;
    color: white;
}

.router-link-active.toCaisse i{
    color: #059669;
}
.router-link-active.toClient{
    border-color:#db2777;
    border-bottom-width: 2px;
    color: white;

}
.router-link-active.toClient i{
    color: #db2777;
}
.router-link-active.toCredit{
    border-color:#d97706;
    border-bottom-width: 2px;
    color: white;

}
.router-link-active.toCredit i{
    color: #d97706;
}
.router-link-active.toSetting{
    border-color:#ef4444;
    border-bottom-width: 2px;
    color: white;

}
.router-link-active.toSetting i{
    color: #ef4444;
}
.fetchingBar{
    position: absolute;
    top: -9px;
    font-size: 5px;
    width: 100%;
    background: #000000;
    color: white;
    padding: 2px 5px;
    height: 10px;
    text-align: center;
}
.fetchingBar::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10%;
    right: 0;
    bottom: 0;
    width: 10%;
    height: 100%;
    background: #ffffff4a;
    animation: bouge 3s infinite;
} 
@keyframes bouge {
    0%{
       left : -10%
    }
    45%{
        left : 100% 
    }
    55%{
        left: 100%;
    }
    100%{
        left: -10%;
    }
} 
.fetchingBar.errorFetch{
    transition: .3s;
    background: #a75151;
    transition: .3s;
    background: #a75151;
    font-size: 14px;
    height: 18px;
    top: -18px;
    display: flex; 
    align-items: center;
    justify-content: center;
}
.fetchingBar.errorFetch::before{
    display: none;
}
.displayFetchError{
    position: absolute;
    top: -200px;
    background: #d44545;
    padding: 10px;
    border-radius: 5px;
    left: 10px;
}
</style>
<script>
      import { store } from '@/components/store';
export default {
    name : 'verticalMenu',
    data(){
        return {
            store,
            displayError : false
        }
    } 
}
</script>