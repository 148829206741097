<template>
    
    <div class="w-full md:w-1/2 xl:w-1/3 p-6">
                <!--experience Card-->
                <div
                    class="bg-gradient-to-b from-blue-200 to-blue-100 border-b-4 border-blue-500 rounded-lg shadow-xl p-3">
                    <div class="flex flex-col items-center">
                        <div class="flex-shrink pr-4">
                            <h5 class="font-bold uppercase text-gray-600">Jours d'existance</h5>
                        </div>
                        <div class="flex flex-1 text-right md:text-center">
                            <div class="w-10 h-15 p-1 mr-1 text-xs">
                                <h3 class="text-3xl"> {{ YearExist }} </h3>
                                <p>Anée</p>
                            </div>
                            <div class="w-10 h-15 p-1 mr-1 text-xs">
                                <h3 class="text-3xl"> {{ mothExist }} </h3>
                                <p>Mois</p>
                            </div>
                            <div class="w-10 h-15 mr-1 p-1 text-xs">
                                <h3 class="text-3xl"> {{ daysExist }} </h3>
                                <p>Jours</p>
                            </div>
                            <div class="w-10 h-15 mr-1 p-1 text-xs">
                                <h3 class="text-3xl"> {{ hourExist }} </h3>
                                <p>Heures</p>
                            </div>
                            <div class="w-10 h-15 p-1 mr-1 text-xs">
                                <h3 class="text-3xl"> {{ minExist }}</h3>
                                <p>Minute</p>
                            </div>
                            <div class="w-10 h-15 p-1 text-xs secondConter">
                                <h3 class="text-3xl"> {{ secExist }} </h3>
                                <p>Second</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script> 
export default {
    name : 'experienceCard',
    props : {
        departDate : {
            default : 0
        }
    },
    data(){
        return {
                    second: 0,
                    minute: 0,
                    heure: 0,
                    Day: 0,
                    month: 0,
                    date :'',
                    year: 0, 
                    
        }
    },
    computed : {
        YearExist() {
            return this.year
        },
        mothExist() {
            return this.month

        },
        daysExist() {
            return this.Day
        },
        hourExist() {
            return this.heure
        },
        minExist() {
            return this.minute
        },
        secExist() {
            return this.second
        }
    }, 
    mounted(){     
        let now = Date.now()
        let sec = (now - this.departDate ) / 1000
        //Year
        let calcYear = sec / 31536000
        let difYear = calcYear - Math.floor(calcYear)
        this.year = Math.floor(calcYear)
        //mois
        let calcMth = difYear * 12
        this.month = Math.floor(calcMth)
        let difMoth = calcMth - Math.floor(calcMth)
        //day
        let calcDay = difMoth * 30
        this.Day = Math.floor(calcDay)
        let difDay = calcDay - Math.floor(calcDay)
        //heure
        let calchour = difDay * 24
        this.heure = Math.floor(calchour)
        let difhour = calchour - Math.floor(calchour)
        //min
        let calmin = difhour * 60
        this.minute = Math.floor(calmin)
        let difmin = calmin - Math.floor(calmin)
        //sec
        let calsec = difmin * 60
        this.second = Math.ceil(calsec)
        setInterval(() => {
            if (this.second === 60) {
                if (this.minute === 60) {
                    if (this.heure === 23) {
                        if (this.Day === 30) {
                            if (this.month === 12) {
                                this.month = 0 //0
                                this.year++
                            }
                            this.Day = 0//0
                            this.month++
                        }
                        this.heure = -1//-1
                        this.Day++
                    }
                    this.heure++
                    this.minute = -1//-1
                }
                this.minute++
                this.second = -1 //-1
            }
            this.second++ 
        }, 1000);
    }
}
</script>